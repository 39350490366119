<template>
  <div class="bg-cyan-400">
    <div
      class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8"
    >
      <h2 class="text-3xl font-extrabold text-gray-900 tracking-tight">
        Tools Built for Discovery
      </h2>
      <p class="mt-4 max-w-3xl text-lg text-gray-800">
        Tools and content designed around your adventure needs
      </p>
      <div
        class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16"
      >
        <div v-for="feature in features" :key="feature.name">
          <div>
            <span
              class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10"
            >
              <FontAwesomeIcon
                :icon="['fad', feature.icon]"
                class="h-6 w-6 text-white"
                aria-hidden="true"
                style="--fa-secondary-opacity: 0.7"
              />
            </span>
          </div>
          <div class="mt-6">
            <h3 class="text-lg font-medium text-gray-800">
              {{ feature.name }}
            </h3>
            <p class="mt-2 text-base text-gray-700">
              {{ feature.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const features = [
  {
    name: "Find Places Near You",
    description: "Use our radial search feature to reveal what’s around you.",
    icon: "magnifying-glass",
  },
  {
    name: "Learn from Others",
    description:
      "Share and review itineraries from previous trips and others so that you can learn what worked and didnt work.",
    icon: "book-open-cover",
  },
  {
    name: "Gear and Conditions",
    description:
      "Learn about new gear and research current conditions such as river CFS, weather, Snow pack and more.",
    icon: "cloud-bolt-sun",
  },
  {
    name: "Tip and Tricks",
    description:
      "From “How To” guides to Tips and Tricks for any adventure, we got you covered.",
    icon: "wand-sparkles",
  },
];
</script>
