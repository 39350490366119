<template>
  <div class="bg-cyan-400">
    <div
      class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8"
    >
      <h2 class="text-3xl font-extrabold text-gray-900 tracking-tight">
        Stay Organized. Plan Together
      </h2>
      <p class="mt-4 max-w-3xl text-lg text-gray-800">
        Tools designed for those that are leading and guiding trips and for
        those who are joining on trips.
      </p>
      <div
        class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16"
      >
        <div v-for="feature in features" :key="feature.name">
          <div>
            <span
              class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10"
            >
              <FontAwesomeIcon
                :icon="['fad', feature.icon]"
                class="h-6 w-6 text-white"
                aria-hidden="true"
                style="--fa-secondary-opacity: 0.7"
              />
            </span>
          </div>
          <div class="mt-6">
            <h3 class="text-lg font-medium text-gray-800">
              {{ feature.name }}
            </h3>
            <p class="mt-2 text-base text-gray-700">
              {{ feature.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const features = [
  {
    name: "Unlimited Itineraries with Voting Rights",
    description:
      "Create multiple itineraries with our route creation tools and put attractions and locations to a vote with the group.",
    icon: "map-location-dot",
  },
  {
    name: "Pack Lists",
    description:
      "Manage Group Pack Lists, Personal Pack List, Personal Gear/Inventory List and more.",
    icon: "list-check",
  },
  {
    name: "Communication",
    description:
      "Take advantage of the group chat features and ability to curate reading and video lists with the group. ",
    icon: "comments",
  },
  {
    name: "Alerts",
    description:
      "Set alerts for changes in conditions, urgent communications and more.",
    icon: "bell",
  },
];
</script>
