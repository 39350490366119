<template>
  <div class="flex flex-col md:flex-row">
    <div class="w-full md:w-1/2 bg-white">
      <img
        class="mx-auto"
        src="/images/download_instructions.webp"
        alt="Download Instructions"
      />
    </div>
    <div class="w-full md:w-1/2 bg-gray-600 py-4 px-6">
      <div class="uppercase text-sm text-gray-300 mt-6">
        NewDescents is a Progressive Web App
      </div>
      <div class="text-white text-2xl my-2">Save to any device</div>
      <div class="text-sm text-gray-300">
        NewDescents is a website but you can also download NewDescents to any
        device and it will work like an app. Simply &quot;save&quot; to your
        smart phone, tablet or computer.
      </div>
    </div>
  </div>
</template>
